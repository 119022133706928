@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Light.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Regular.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Medium.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/font/NotoSansKR-Bold.woff2')
    format('woff2');
  unicode-range: U+AC00-D7A3;
}

.material-icons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: top;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.md-10 {
    font-size: 10px;
  }
  &.md-12 {
    font-size: 12px;
  }
  &.md-14 {
    font-size: 14px;
  }
  &.md-16 {
    font-size: 16px;
  }
  &.md-18 {
    font-size: 18px;
  }
  &.md-20 {
    font-size: 20px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-48 {
    font-size: 48px;
  }
  &.md-96 {
    font-size: 96px;
  }
  &.white {
    color: #fff;
  }
  &.black {
    color: #222;
  }
  &.primary {
    color: @primary-color;
  }
  &.gray {
    color: #7e7e7e !important;
  }
  &.cursor {
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    color: #a9a6a6;
  }
}
.material-icons.outlined {
  font-family: 'Material Icons Outlined' !important;
}

* {
  box-sizing: inherit;
  font-family: 'Noto Sans KR', 'Roboto', sans-serif !important;
}

/*
 * customize ant design 
 */
.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100% !important;
}
.ant-pagination {
  text-align: center;
}
.ant-table-pagination-right {
  justify-content: center;
}
.ant-form-item-label {
  overflow: visible;
}
.ant-menu-submenu-popup {
  position: fixed;
}
.ant-statistic-content-value {
  font-size: 18px;
}
.ant-form-item-explain.ant-form-item-explain-success {
  color: @primary-color;
}
.ant-message-custom-content {
  display: flex;
}
.ant-message-notice-content {
  border-radius: 4px;
  min-height: 56px;
  padding: 16px 20px 12px 20px;
  max-width: 600px;
}
.ant-message-custom-content span {
  white-space: pre-line;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -1px;
}
.ant-message {
  top: 20% !important;
  z-index: 1200;
}

.ant-message-warning .anticon {
  color: #f3d429;
}
.ant-message-info .anticon {
  color: #1890ff;
}
.ant-message-error .anticon {
  color: #ff4d4f;
}
.ant-message-success .anticon {
  color: #29bf5f;
}
.ant-message .anticon {
  font-size: 20px;
}
.ant-scroll-number-only-unit {
  position: relative;
  top: -1px;
}
.ant-notification-notice-description {
  white-space: pre-line;
}
.ant-statistic-content-value,
.ant-statistic-content {
  font-size: 18px;
}
.ant-page-header {
  padding-right: 0;
}
.ant-page-header-back {
  margin-right: 14px;
}
.ant-page-header-heading-sub-title {
  font-size: 18px;
  color: @primary-color;
}
.ant-page-header-heading-left {
  overflow: visible;
  flex-wrap: nowrap;

  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
}
.ant-pagination-options {
  display: none;
}
.ant-modal-title {
  min-height: 16px;
}
.ant-modal-wrap {
  z-index: 1010;
}
.ant-modal-content {
  border: 1px solid #5b96f3;
}
.ant-modal-body {
  padding: 24px 40px 32px;
}
.ant-modal-confirm {
  .ant-modal-body {
    padding: 24px;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;

    .ant-btn {
      font-size: 12px;
      padding: 8px 22px;
      line-height: 18px;
      height: auto;
    }
  }
}

.ant-page-header-heading-title {
  font-size: 24px;
  letter-spacing: -1px;
  line-height: 34px;
  flex-shrink: 0;
}
.ant-badge-dot {
  width: 6px;
  min-width: 6px;
  height: 6px;
  background-color: #5b96f3;
  border-radius: 50%;
}
.ant-menu-vertical .ant-menu-item-only-child {
  font-size: 16px;
  letter-spacing: -1px;
}
.ant-menu-inline {
  border-right: none;
}
.ant-radio-button-wrapper-checked.ant-radio-button-wrapper:first-child {
  border-right-color: @primary-color !important;
}
.ant-radio-group-outline {
  display: flex;
}
.ant-radio-button-wrapper {
  height: 44px;
  line-height: 44px;
  flex: 1 1 100%;
  text-align: center;
  padding: 0 8px;
  letter-spacing: -0.5px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}
.ant-card-bordered {
  border-color: #d3d3d3;
}
.ant-btn::after {
  display: none;
}
.ant-btn-primary {
  box-shadow: none;

  &:focus {
    background-color: #3785f7;
    border-color: #3785f7;
  }
}
/* form */
.ant-form-item {
  margin-bottom: 16px;

  &.ant-form-item-with-help {
    margin-bottom: 0;
  }
}
.ant-form-item-label > label {
  height: 44px;
  font-size: 18px;
  color: #222;
  letter-spacing: -1px;
}
input.ant-input:not(.ant-transfer-list-search),
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-input,
.ant-picker {
  height: 44px;
  padding-left: 16px;
  letter-spacing: -0.5px;

  &::placeholder {
    color: #a9a6a6;
  }
  &.ant-input-sm {
    height: 32px;
  }
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 36px;
  padding-left: 16px;

  .ant-select-selection-item {
    line-height: 34px;
  }
}

.ant-input-affix-wrapper {
  height: 44px;
  padding-left: 0;
}

.ant-select-multiple .ant-select-selector {
  min-height: 44px;
}
.ant-select-selection-placeholder {
  color: #a9a6a6;
}
input.ant-input[disabled],
.ant-picker-input > input[disabled],
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator,
.ant-picker.ant-picker-disabled .ant-picker-suffix,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-input-number-disabled,
.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:hover,
textarea.ant-input[disabled] {
  color: #a9a6a6;
  &.ant-radio-button-wrapper-checked {
    background-color: #d5d5d5;
    color: #fff;
  }
}
.ant-select-item-option-content {
  white-space: pre-wrap !important;
}

.ant-input-number-input {
  height: 42px;
}
input.ant-input-number-input {
  font-size: 14px;
}
.ant-input:focus,
.ant-input-number:focus,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}
.ant-form-item-control-input {
  min-height: 44px;
}

.ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-radio-wrapper {
  line-height: 42px;
}
.ant-radio-wrapper {
  font-size: 16px !important;
  letter-spacing: -1px;
}
.ant-input-affix-wrapper > input.ant-input {
  height: auto;
}
.ant-input-number.hide-handler > .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-group > .ant-input:first-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-input-group-addon {
  width: 50px;
  border: none;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -1px;
  background-color: transparent;
}

.ant-input-textarea {
  &.ant-input-textarea-show-count {
    font-size: 10px;
  }

  textarea {
    padding: 12px 16px !important;
  }
}
.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 44px;
}
.ant-select- .ant-select-arrow {
  color: #a9a6a6;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #d3d3d3;
  border: none;

  &::after {
    border-color: #fff;
  }
}
.ant-checkbox-disabled + span {
  color: #a9a6a6;
}

.ant-checkbox-inner {
  border-radius: 2px;
}
.ant-btn {
  height: 44px;
  letter-spacing: -0.8px;
  border-color: @primary-color;
  box-shadow: none;

  &.ant-btn-sm {
    height: 32px;
  }

  &[disabled],
  &[disabled]:hover {
    opacity: 0.4;
    color: #222;
    background: #fff;
    border-color: @primary-color;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }

  &.ant-btn-link {
    border-color: transparent;
    height: 24px;
  }

  &.ant-btn-gray {
    color: #7e7e7e;
    border-color: #d3d3d3;
  }
  &.ant-btn-waiting {
    border: none;
    background-color: #eff1f8;
    color: #9195a5;
    font-weight: 500;
    cursor: default;

    &:hover {
      background-color: #eff1f8;
      color: #9195a5;
    }

    &[disabled],
    &[disabled]:hover {
      opacity: 1;
    }
  }
  i.material-icons {
    margin-right: 8px;
    color: @primary-color;
    line-height: 22px;
  }
  i,
  span {
    vertical-align: middle;
  }
}
a.ant-btn {
  padding-top: 4px;
  line-height: 32px;
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: relative;
  top: 2px;
  left: -1px;
  color: @primary-color;
  font-size: 18px;
  margin-right: 0;
}
.ant-form-item-explain {
  position: relative;
  font-size: 10px;
  min-height: 16px;
}

/* table */
.ant-table-wrapper {
  &.horizontal .ant-table {
    th {
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      padding: 12px 20px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
    }

    td {
      padding: 12px 20px !important;
      font-size: 16px;
      color: #222;
    }
  }
  .ant-table {
    overflow: hidden;
  }
  .ant-table-thead > tr > th,
  .ant-table-small tr > th {
    background-color: #f5f9ff;
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: #f5f9ff !important;
  }

  .ant-table-tbody > tr.ant-table-row-selected > td,
  .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: #dcf3ff;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    letter-spacing: -0.5px;
  }

  .ant-table-thead > tr > th {
    padding: 10px 4px;
    font-weight: 400;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 4px;
    line-height: 20px;
    font-weight: 400;

    &.ant-table-selection-column {
      padding: 12px;
    }
  }

  .ant-table-tbody > tr > td {
    color: #7e7e7e;
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 24px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
  }

  .ant-table-footer {
    padding: unset;
    color: unset;
    background-color: unset;
    border-radius: unset;
  }
}

.ant-checkbox-inner::after {
  display: block !important;
}

/* statistics */
.ant-statistic {
  .ant-statistic-title {
    text-align: center;
    color: #283c54;
    opacity: 0.5;
    font-size: 12px;
  }
  .ant-statistic-content {
    text-align: center;

    .ant-statistic-content-value {
      font-size: 16px;
      font-weight: 500;
      color: #4e5968;
      letter-spacing: -0.89px;
      line-height: 1.5;
      word-break: break-all;
    }
  }
}

/* collapse */
.ant-collapse-header {
  border-radius: 8px;
}

// 담당자, 바이어 목록
.ant-table-wrapper.secondary {
  table {
    border-spacing: 0 8px;
    thead.ant-table-thead {
      th {
        padding-top: 8px;
        padding-bottom: 8px;
        border-top: 1px solid #d7e7fe;
        border-bottom: 1px solid #d7e7fe;
      }
    }
    tbody.ant-table-tbody {
      tr {
        &.ant-table-row-selected > td {
          background-color: #dcf3ff;
        }
        td {
          border: none;
          background-color: #f6f7f9;
          color: #4e5968;

          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
}

.ant-table-wrapper.tertiary {
  & > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
    border-radius: 0;

    & > .ant-table-container > .ant-table-content > table {
      & > thead > tr > th.ant-table-cell {
        border-top: 2px solid #3785f7;
        height: 46px;
        text-align: center;
        background-color: #f9f9f9;
        color: #4e5968;
        font-weight: 500;
      }

      & > tbody > tr > td.ant-table-cell {
        font-weight: 500;
        color: #4e5968;
      }
    }
  }
}

// descriptions
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-row,
.ant-descriptions-bordered .ant-descriptions-view {
  border-color: #d8d8d8;
}

/*
 * customize react-pdf
 */
.react-pdf__Document {
  width: 100%;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.react-pdf__Page__textContent {
  user-select: none;
}

/*
 * customize react-pdf-reader
 */
._1CDf-Fj0nLQTDw84Sho5JS {
  height: auto !important;
}
._1CDf-Fj0nLQTDw84Sho5JS > canvas {
  width: 100%;
}
.react-pdf__Page__textContent {
  display: none;
}

/*
 * customize suneditor-react 
 */
.sun-editor {
  border: none !important;
}
.se-wrapper-inner {
  padding: 0 !important;
}
.se-resizing-bar {
  display: none !important;
}

.abcdefg {
  width: 200px;
}

@primary-color: #3785f7;@error-color: #ff4d4f;@link-color: #3785f7;@disabled-color: #e7e7e7;@border-radius-base: 4px;@screen-xs: 360px;@screen-sm: 600px;